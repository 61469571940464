:root {
  --primary-gray: #2D3648 !important;
  --black: #000000 !important;
  --white: #fff !important;
  --danger: red !important;
  --second-gray: #A1A1AA !important;
  --clear-gray: #F5F5F4 !important;
  --gray-option-one: #1F1F1F !important;
  --gray-option-two: #838383 !important;
  --gray-option-three: #CCCCCC !important;
  --blue: #0021F5 !important;
  --green: #00CC33 !important;
  --violet: #6600CC !important;
}
