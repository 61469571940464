@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
/*@import url('https://db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text+Regular');*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.d-flex {
    display: flex;
    & .flex-center-y {
        justify-content: center;
    }
    &.d-flex-center-all {
        justify-content: center;
        align-items: center;
    }
    &.d-flex-left {
        justify-content: left;
    }
}

.align-center {
    align-items: center;
}

.margin-r-10 {
    margin-right: 10px !important;
}

.margin-l-10 {
    margin-left: 10px !important;
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

html body {
    font-weight: 500;
}

html,
body,
h1,
h2,
#root h3,
#root h4,
#root h5,
#root h6,
#root p,
#root .dashboard-menu .MuiTypography-root,
#root .MuiTableCell-head #root .MuiBreadcrumbs-li,
body ul.MuiMenu-list li.MuiMenuItem-root,
body label,
body span,
body label span,
body input.MuiInputBase-input,
body .MuiSelect-select,
body .MuiButton-root,
body .MuiPaper-root,
body .MuiGrid-root,
body .MuiBox-root,
body .MuiButtonBase-root {
    font-family: 'Manrope', sans-serif !important;
    /* font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
}

html,
body,
#root h1,
#root h2,
#root h3,
#root h4,
#root h5,
#root h6,
#root p,
body label {
    line-height: 1.4;
}

.MuiModal-root .MuiTypography-root {
    font-family: 'Manrope', sans-serif !important;
}

.MuiModal-root h4.MuiTypography-root {
    font-size: 15px;
    font-weight: 700;
}

body td.MuiTableCell-root,
body tbody th.MuiTableCell-root {
    font-family: 'Manrope', sans-serif !important;
    border-bottom: 1px solid rgb(245, 245, 245);
}

body table .MuiBox-root,
body thead th.MuiTableCell-root {
    font-family: 'Manrope', sans-serif !important;
}

body table .MuiBox-root .MuiButtonBase-root .Mui-TableHeadCell-Content,
body table .MuiBox-root .MuiButtonBase-root .Mui-TableHeadCell-Content .MuiTableSortLabel-icon {
    color: rgba(255, 255, 255, 0.54);
}

body tbody td.MuiTableCell-root {
    font-family: 'Manrope', sans-serif !important;
    white-space: inherit;
}

body .MuiButtonBase-root {
    font-size: 14px;
    text-transform: initial;
}

body .customer-panel .MuiButtonBase-root {
    padding: 8px 30px;
    height: 41px;
    line-height: 1;
}

body .customer-panel .MuiButtonBase-root.MuiCheckbox-root {
    padding: 9px !important;
    height: auto;
}

body .dashboard-menu .MuiButtonBase-root .MuiTypography-root {
    font-size: 15px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(233, 233, 253, 0.35) !important;
}

body .main-wrapper th.MuiTableCell-root {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 400;
    color: #fff;
    background-color: #353535;
    justify-content: center;
}

table tr.MuiTableRow-root:nth-of-type(2n+1) {
    background-color: rgba(233, 233, 253, 0.18);
}

body .main-wrapper .steps-page tbody th.MuiTableCell-root {
    background-color: unset;
    color: #000;
}

.css-q4cnup-MuiTableHead-root .css-ccs4oe-MuiTableRow-root {
    background-color: #e9e9fd;
}

#root .main-wrapper h1 {
    font-size: 18px;
    font-weight: 700;
}

#demo-radio-buttons-group-label {
    color: #111;
    font-weight: 500;
}

body .MuiTableCell-root {
    font-size: 14px;
    padding: 6px 4px;
    font-weight: 500;
}

body a,
a.MuiTypography-root {
    cursor: pointer;
    color: #0000ff;
    text-decoration: none;
    font-size: 14px;
}

a.btn-login {
    background-color: #fff;
    color: #0000ff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    min-width: 64px;
    padding: 6px 16px;
    text-decoration: none;
    border-radius: 0px;
    margin-left: auto;
}

header.header-customer,
header.header-admin {
    background-color: #0000ff;
    /* padding: 5px 0; */
}

header.app_bar {
    background-color: #fff;
}

header.app_bar .MuiContainer-root {
    padding: 0;
}

header.app_bar button.MuiButtonBase-root {
    margin: 0;
}

.app_bar_right.MuiBox-root {
    /* position: absolute; */
    top: 0;
    right: 0;
}

.app_bar_right button.MuiButtonBase-root {
    padding: 20px;
    height: auto;
}

button.btn-primary,
a.btn-primary {
    background-color: #0000ff;
    border-radius: 0;
    box-shadow: none;
    vertical-align: center !important;
    align-content: center !important;
}

button.btn-primary:hover,
button.btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
    background-color: #353535;
    box-shadow: none;
}

button.btn-secondary,
a.btn-secondary {
    background-color: #353535;
    border-radius: 0;
    box-shadow: none;
}

button.btn-secondary:hover,
button.btn-secondary:focus,
a.btn-secondary:hover,
a.btn-secondary:focus {
    background-color: #0000ff;
    box-shadow: none;
}

.MuiButton-outlinedPrimary.btn-outline-primary {
    border-color: #0000ff;
    color: #0000ff;
    border-radius: 0;
    vertical-align: center !important;
    align-content: center !important;
}

.MuiButton-outlinedPrimary.btn-outline-primary:hover,
.MuiButton-outlinedPrimary.btn-outline-primary:focus {
    border-color: #0000ff;
    background-color: #0000ff;
    color: #fff;
}

.invalid-feedback {
    font-size: 12px;
}

/* Home page style */
.home-center {
    padding: 80px 0;
    background-color: #fff;
}

body .theme-title-color {
    color: #0000ff;
}

.home-center .title {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 700;
    font-size: 37px;
    color: #353535;
    margin-bottom: 40px;
}

.home-cards .home-card-single {
    border: 2px solid #353535;
    border-radius: 20px;
    padding: 24px;
    background-color: #fff;
    box-shadow: none;
    text-align: left;
}

.home-cards .home-card-single .card-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
}

.home-cards .home-card-single .card-icon img {
    width: 100%;
}

.home-cards .home-card-single.active {
    filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.2));
    border-color: #0000ff;
}


.home-cards .home-card-single .MuiCardHeader-root,
.home-cards .home-card-single .MuiCardContent-root {
    padding: 0;
}

.home-cards .home-card-single .MuiCardHeader-title {
    font-size: 24px;
    font-family: 'Manrope', sans-serif !important;
    line-height: 1;
    margin-bottom: 10px;
    text-align: left;
    color: #353535;
    font-weight: 700;
}

.home-cards .home-card-single .MuiCardHeader-subheader {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 15px;
    text-align: left;
    color: #7F7F7F;
}

#root .home-cards .home-card-single h2 {
    /* font-family: 'PragmaticaBold' !important; */
    font-family: 'Manrope', sans-serif !important;
    font-size: 20px;
    color: #353535;
    font-weight: 700;
}

.home-cards .home-card-single .MuiCardActions-root {
    padding: 0;
}

.home-cards .home-card-single button {
    padding: 8px 15px;
    height: 41px;
    background: #0000ff;
    border-radius: 0;
    color: #fff;
    box-shadow: none;
    width: 150px;
}

.home-cards .MuiGrid-item:first-child .home-card-single button {
    background-color: #ea3446;
}

.home-cards .MuiGrid-item:last-child .home-card-single button {
    background-color: #751AA3;
}

h2.choose-your-plan {
    /* font-family: 'PragmaticaMedium' !important; */
    font-family: 'Manrope', sans-serif !important;
    font-weight: 700;
    font-size: 28px;
    color: #353535;
    display: flex;
    align-items: center;
}

.arrow svg {
    font-size: 40px;
}

.arrow {
    line-height: 1;
    display: flex;
    margin-left: 10px;
}

/* login styles */
.login-wrapper {
    /* background: rgb(0, 0, 255); */
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
}

button.toggle_btn {
    border-radius: 0;
    font-size: 16px;
    /* width: 100%; */
    /* background-color: #f5f5f5; */
}

.brand-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand-wrapper img {
    width: 80%;
}

button.toggle_btn:hover,
button.toggle_btn:focus {
    /* background-color: #e5e5f0; */
}

.dashboard-menu {
    height: calc(100vh - 50px);
}

.dashboard-menu .MuiListItemIcon-root {
    min-width: 40px;
    color: #0000ff;
}

.dashboard-menu .MuiListItemButton-root {
    padding-top: 5px;
    padding-bottom: 5px;
}

.dashboard-menu .MuiListItemButton-root:hover {
    background-color: #E5E5E5;
}

main.main-wrapper {
    position: relative;
    min-height: 100vh;
    height: auto;
    background-color: #E5E5E5;
}

main.main-wrapper button.btn-primary,
main.main-wrappera.btn-primary {
    padding: 7px 15px;
}

.main-wrapper .MuiButtonBase-root.MuiButton-sizeSmall {
    padding: 3px 10px;
}

.MuiFormLabel-asterisk {
    color: red !important;
}

.csv-file-box .css-1sasres {
    background: #E5E5E5;
    border: none;
}

.csv-file-box .css-1sasres svg.MuiSvgIcon-colorPrimary {
    color: #353535;
}

.csv-file-box .css-1sasres button.MuiButtonBase-root {
    background-color: #0000ff;
    border-radius: 0;
    box-shadow: none;
}

.csv-file-box .css-1sasres button.MuiButtonBase-root:hover {
    background-color: #353535;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0000ff !important;
    color: #0000ff;
}

label.MuiFormLabel-root.Mui-focused {
    color: #0000ff;
}

.recent-orders h2.MuiTypography-h6,
.recent-deposite h2.MuiTypography-h6,
h2.MuiTypography-h6 span.card-title {
    color: #0000ff;
    font-size: 16px;
    font-weight: 700;
}

header nav .MuiBreadcrumbs-ol .MuiBreadcrumbs-li a.MuiTypography-root {
    color: #fff;
    cursor: pointer;
}

#root .recent-deposite .MuiTypography-h4 {
    font-size: 24px;
    font-weight: 700;
    /* font-family: 'PragmaticaExtendedBold' !important; */
}

.steps-radio {
    width: 100%;
}

.steps-radio label.MuiFormControlLabel-root {
    width: calc(25% - 30px);
    margin-right: 40px;
}

.steps-radio label.MuiFormControlLabel-root .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding-left: 16px;
}

div.css-1mjty8n-MuiGrid-root>.MuiGrid-item {
    padding-top: 13px;
}



body .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
body .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
body .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #0000ff;
}

body .check-box {
    /* padding: 0; */
}

body::selection {
    background: #0000ff !important;
    color: #fff !important;
}


.main-inner {
    min-height: calc(100vh - 164px);
    height: auto;
}

.main-inner.dashboard-page {
    min-height: calc(100vh - 140px);
}

.main-inner.steps-page {
    /* min-height: calc(100vh - 140px); */
}

.steps-page h6 {
    font-size: 15px;
    font-weight: 700;
}

.table-action {
    display: flex;
    padding-right: 10px;
}

.table-action .MuiButtonBase-root {
    border-radius: 100%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 26px;
    height: 26px;
    margin-right: 2px;
}

.table-action .MuiButtonBase-root:hover {
    background-color: #0000ff;
    color: #fff;
}

.table-action .MuiButtonBase-root svg {
    font-size: 18px;
}

.MuiFormLabel-asterisk {
    color: red !important
}

.table-action-btn {
    display: flex;
    gap: 2px;
    flex-direction: column;
}

.table-action-btn .btn {
    padding: 4px;
}

.form-fill-Inquiry table.MuiTable-root tbody tr td:last-child,
.form-fill-Inquiry table.MuiTable-root thead tr th:last-child {
    width: 100px;
}

#root .MuiPaper-root,
#root .MuiInputBase-root {
    border-radius: 0;
}

.app_bar {
    position: relative;
}

.app_bar_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 15px;
}

.need-help {
    height: 100%;
}

.search-wrapper {
    line-height: 1;
}

.search-wrapper svg {
    cursor: pointer;
}


/* Footer styles */
.footer {
    background-color: #222628;
    color: #fff;
    padding: 60px 0;
    font-size: 14px;
    position: relative;
    margin-top: 70px;
}

.footer-top-square {
    position: absolute;
    top: 0;
    left: 0;
}

.footer-top-square::after {
    content: "";
    width: 50px;
    height: 50px;
    background-color: #222628;
    position: absolute;
    bottom: 0;
    left: 0;
}

.footer-top-square::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #222628;
    position: absolute;
    bottom: 50px;
    left: 50px;
}

.footer-bottom-square {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #fff;
}

.footer p {
    /* line-height: 1.3; */
}

.footer-bottom {
    margin-top: 30px;
    /* border-top: 1px solid #333; */
    font-size: 14px;
    color: #999;
}

.footer-bottom .footer-bottom-links {
    text-align: right;
    padding-right: 80px;
}

.footer-bottom .footer-bottom-links a.MuiLink-root {
    color: #999;
    font-weight: 300;
    margin: 0 15px;
}

.footer-bottom .footer-bottom-links a.MuiLink-root:hover {
    color: #fff;
}

.social_links,
.menu_items {
    list-style: none;
    padding-left: 0;
}

.menu_items li a {
    color: #fff;
    font-size: 14px;
}

.menu_items li {
    margin-bottom: 10px;
}

.social_links {
    display: flex;
    gap: 10px;
}

.social_links li a {
    font-size: 14px;
    width: 30px;
    height: 30px;
    border: 1px solid #0000ff;
    border-radius: 10px;
    transition: 0.3s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social_links li a:hover,
.social_links li a:focus {
    background-color: #0000ff;
}

.social_links li a svg {
    font-size: 18px;
}

.scroll-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 40px;
    height: 40px;
    background-color: #0000ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.hero-banner {
    height: 500px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.pixel-pattern {
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 50px;
    z-index: 2;
}

.pixel-pattern img {
    width: 100%;
    height: auto;
    min-height: 50px;
}

.hero-title {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
}

.hero-title h2 {
    font-size: 37px;
    color: #fff;
    text-shadow: 1px 1px 5px #000;
    font-weight: 800;
}

.hero-banner img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center center;
}

.shop-search {
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.shop-search::after {
    content: "";
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 190px;
}

.shop-search input {
    background-color: #fff;
}

h1.section-title,
.new-call-details h2 {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 40px !important;
}

.shop-now {
    padding: 60px 0;
    position: relative;
    z-index: 1;
}

.shop-now h3,
.shop-search h3 {
    font-size: 15px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
    color: #353535;
}

.shop-now h3 img {
    width: 16px;
}


.shop-search .enrollment_type .radio-groups {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 15px;
}

.shop-search .enrollment_type h3 {
    text-align: center;
}

.text-center {
    text-align: center;
}

.radio-groups .form-group {
    margin-bottom: 5px;
}

.enrollment_type {
    max-width: 450px;
    margin: auto;
    margin-top: 30px;
}

/* radio styles */

#root .customer-panel input[type="checkbox"]+label,
#root .customer-panel input[type="radio"]+label {
    font-size: 14px;
}

.shop-search input[type="radio"]:checked,
.shop-search input[type="radio"]:not(:checked),
#root .customer-panel input[type=radio]:checked,
#root .customer-panel input[type=radio]:not(:checked),
#root .customer-panel input[type=checkbox]:checked,
#root .customer-panel input[type=checkbox]:not(:checked),
.MuiModal-root input[type="checkbox"]:checked,
.MuiModal-root input[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    appearance: none;
}

.shop-search input[type="radio"]:checked+label,
.shop-search input[type="radio"]:not(:checked)+label,
#root .customer-panel input[type=radio]:checked+label,
#root .customer-panel input[type=radio]:not(:checked)+label,
#root .customer-panel input[type=checkbox]:checked+label,
#root .customer-panel input[type=checkbox]:not(:checked)+label,
.MuiModal-root input[type="checkbox"]:checked+label,
.MuiModal-root input[type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #353535;
}

.shop-search input[type="radio"]:checked+label:before,
.shop-search input[type="radio"]:not(:checked)+label:before,
#root .customer-panel input[type=radio]:checked+label:before,
#root .customer-panel input[type=radio]:not(:checked)+label:before,
#root .customer-panel input[type=checkbox]:checked+label:before,
#root .customer-panel input[type=checkbox]:not(:checked)+label:before,
.MuiModal-root input[type="checkbox"]:checked+label:before,
.MuiModal-root input[type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #0000ff;
    border-radius: 0;
    background: unset;
}

.shop-search input[type="radio"]:checked+label:after,
.shop-search input[type="radio"]:not(:checked)+label:after,
#root .customer-panel input[type=radio]:checked+label:after,
#root .customer-panel input[type=radio]:not(:checked)+label:after,
#root .customer-panel input[type=checkbox]:checked+label:after,
#root .customer-panel input[type=checkbox]:not(:checked)+label:after,
.MuiModal-root input[type="checkbox"]:checked+label:after,
.MuiModal-root input[type="checkbox"]:not(:checked)+label:after {
    /* content: '';
    width: 10px;
    height: 10px;
    background: #0000ff;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; */
    content: "";
    width: 6px;
    height: 10px;
    top: 8px;
    left: 3px;
    position: absolute;
    transform: rotate(45deg) translateY(-50%);
    border-bottom: 3px solid transparent;
    border-bottom-color: transparent;
    border-right: 3px solid transparent;
    border-right-color: transparent;
    border-bottom-color: #0000ff;
    border-right-color: #0000ff;
}

#root .customer-panel input[type=checkbox]:not(:checked)+label:after,
#root .customer-panel input[type=radio]:not(:checked)+label:after,
.shop-search input[type="radio"]:not(:checked)+label:after,
.MuiModal-root input[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.shop-search input[type="radio"]:checked+label:after,
.shop-search input[type="radio"]:checked+label:after,
#root .customer-panel input[type="radio"]:checked+label:after,
.MuiModal-root input[type="checkbox"]:checked+label:after {
    opacity: 1;
    /* -webkit-transform: scale(1);
    transform: scale(1); */
}

.radio-groups.radio-inline {
    display: flex;
    justify-content: space-between;
}

/* end */
body .content-wrapper .customer-panel table {
    background-color: #fff;
}

.search-compare {
    background-color: #e5e5e5;
    padding: 60px 0;
    position: relative;
    z-index: 1;
}

body .search-compare td.MuiTableCell-root,
body .search-compare tbody th.MuiTableCell-root,
.search-compare th.MuiTableCell-root {
    border: 1px solid #353535;
    /* text-align: left; */
}

/* body .search-compare .previous-value td.MuiTableCell-root, */
body .search-compare .previous-value tbody th.MuiTableCell-root,
.search-compare .previous-value th.MuiTableCell-root {
    border: 1px solid #ccc;
    /* text-align: left; */
}



body .search-compare .advance-search td.MuiTableCell-root {
    border: none !important;
}

body .search-compare .advance-search .MuiPaper-root {
    border: 1px solid #353535;
    box-shadow: none;
}

.search-compare table tr.MuiTableRow-root:nth-of-type(2n+1) {
    background-color: unset;
}

.search-compare h2 {
    font-size: 37px;
    font-weight: 700 !important;
    margin-bottom: 40px;
}

.search-compare h1.section-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px;
}

.previous-value h2.section-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.search-compare button.table-btn {
    background-color: #dbdbff;
    color: #353535;
    border-radius: 0;
    padding: 8px 20px;
    height: 41px;
}

.search-compare button.table-btn:after,
.search-compare button.table-btn:before {
    display: none !important;
}

.search-compare button.table-btn:hover {
    background-color: #0000ff !important;
    color: #fff;
}

.search-compare .advance-search h6 {
    font-size: 15px;
    font-weight: 700;
}

.search-compare .advance-search button {
    min-width: 150px;
}

.search-compare .advance-search .MuiSvgIcon-root {
    color: #0000ff;
}

.advance-search button.btn-link {
    justify-content: flex-start;
}

body .customer-panel .advance-search table {
    margin-bottom: 0 !important;
}

body .customer-panel .advance-search .MuiButtonBase-root.btn-link {
    border: none;
    color: #0000ff !important;
    justify-content: flex-start;
    text-align: left;
    padding: 0 20px !important;
    margin-bottom: 20px;
}

button.btn-link {
    color: #0000ff;
}

button.btn-link:hover,
button.btn-link:focus {
    color: #2424fd;
}

.MuiModal-root .modal-close {
    position: absolute;
    top: -10px;
    right: -10px;
    box-shadow: none !important;
    font-size: 22px;
    height: 50px;
    width: 50px;
}

div.MuiModal-root {
    overflow: auto;
    /* padding-top: 10;
    top: 0; */
}

div.MuiModal-root .MuiBox-root {
    top: 5%;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-radius: 0;
}

#root input,
#root fieldset,
body input,
body fieldset {
    border-color: #b5b5b5;
    border-radius: 0 !important;
}

.text-cetner {
    text-align: center;
}

#root .customer-panel input {
    /* height: 41px;
    padding: 0 15px; */
    font-weight: 500;
}

#root .customer-panel input[type=checkbox] {
    height: auto;
}

.table_btn {
    background-color: #dbdbff;
    color: #373737;
    padding: 8px;
    border-radius: 50px;
    transition: 0.3s;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
}

.table_btn:hover {
    background-color: #00f;
    color: #fff;
}

.blue-light_bulb img {
    margin: auto;
    max-width: 16px;
}

.blue-light_bulb {
    position: relative;
    top: 2px;
    left: 3px;
    display: inline-block;
}

table .css-19bsh9e-MuiTableRow-root:last-child td,
table .css-19bsh9e-MuiTableRow-root:last-child th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

#root .MuiBreadcrumbs-li {
    font-size: 14px;
}

.MuiModal-root #modal-modal-title {
    font-size: 37px;
    font-weight: 700 !important;
}

.login-wrapper {
    margin: 60px auto;
}

/* / new styles added for bug fixes /  */
.e-popup.e-popup-open.e-dialog {
    height: auto !important;
}

.e-schedule-dialog.e-device .e-save-icon,
.e-schedule-dialog.e-device .e-save-icon::before,
.e-schedule-dialog.e-device .e-back-icon {
    color: #fff;
}

.e-schedule-dialog.e-device .e-save-icon {
    padding-right: 10px;
    padding-top: 5px;
}

.e-schedule-dialog.e-device .e-back-icon {
    padding-top: 5px;
    padding-left: 10px;
}

.profileDiv .fa-pen {
    left: 50%;
    transform: translateX(-50%);
}

.e-quick-popup-wrapper.e-device {
    width: 100%;
    max-width: 70vw;
    left: 50% !important;
    top: 50% !important;
    height: auto !important;
    max-height: fit-content !important;
    transform: translate(-50%, -50%) !important;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper {
    background: unset;
}

div.Mui-TableHeadCell-Content-Wrapper {
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
}

.contract-review,
.thanks-section {
    background-color: #e5e5e5;
    padding: 60px 0;
}

.thanks-section .MuiPaper-root {
    height: 100%;
}

.contract-review button {
    width: 100%;
    max-width: 190px;
    margin: 5px;
}

.contract-review h2 {
    font-size: 37px;
    font-weight: 700 !important;
    margin-bottom: 40px !important;
}

.contract-review .contract-review-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    border: 1px solid #eee;
    margin-bottom: 15px;
}

.contract-review .contract-review-list .MuiListItemText-root {
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

.contract-review .contract-review-list .MuiListItemText-root:last-child {
    border: none;
}

.contract-review .contract-review-list .MuiListItemText-root span.MuiTypography-root {
    display: flex;
    column-gap: 20px;
}

.contract-review .contract-review-list .MuiListItemText-root span.MuiTypography-root>span {
    flex: 1;
}

.contract-review .contract-review-list .MuiListItemText-root span.MuiTypography-root>span:first-child {
    text-align: right;
    font-weight: 600;
}

div.MuiDialog-root .MuiDialogActions-root {
    text-align: center;
    justify-content: center;
    padding-bottom: 20px;
}

div.MuiDialog-root .MuiDialogActions-root button.MuiButton-outlined {
    border-radius: 0;
    border-color: #0000ff;
    color: #0000ff;
    box-shadow: none;
}

div.MuiDialog-root .MuiDialogActions-root button.MuiButton-outlined:hover {
    background-color: #0000ff;
    color: #fff;
}

div.MuiDialog-root .MuiDialogActions-root button.MuiButton-contained {
    border-radius: 0;
    background-color: #0000ff;
    box-shadow: none;
    /* color: #0000ff; */
}

div.MuiDialog-root .MuiDialogActions-root button.MuiButton-contained:hover {
    background-color: #353535;
}


.ReactModalPortal .ReactModal__Overlay {
    z-index: 9999 !important;
}

.p-relative {
    position: relative;
}

.ic-info {
    position: absolute;
    top: 24px;
    right: 5px;
    cursor: pointer;
}

.MuiTableCell-head,
.Mui-TableHeadCell-Content-Wrapper {
    word-wrap: break-word;
    white-space: pre-line !important;
    line-height: 1;
}

.Mui-TableHeadCell-Content-Labels {
    overflow: visible !important;
}

th.MuiTableCell-root {
    border-right: 1px solid #ccc
}

body button.MuiButton-containedPrimary.btn-primary {
    position: relative;
    color: #FFFFFF;
    border: 1px solid #0000ff;
    z-index: 1;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

body button.MuiButton-containedPrimary.btn-primary::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: #0000ff;
}

body button.MuiButton-containedPrimary.btn-primary::after {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 150%;
    height: 100%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    -ms-transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    -webkit-transition: transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1);
    transition: transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1);
    background-color: #fff;
}

body button.MuiButton-containedPrimary.btn-primary:hover {
    color: #0000ff !important;
    border-color: #0000ff;
}

body button.MuiButton-containedPrimary.btn-primary:hover:after {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    -ms-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
}

body .search-compare .advanced-search td.MuiTableCell-root {
    border: none;
}

body button.MuiButton-outlinedPrimary.btn-outline-primary {
    position: relative;
    color: #0000ff;
    overflow: hidden;
    border-color: #0000ff;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

body button.MuiButton-outlinedPrimary.btn-outline-primary::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: #fff;
}

body button.MuiButton-outlinedPrimary.btn-outline-primary::after {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 150%;
    height: 100%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    -ms-transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    transform: rotate3d(0, 0, 1, -10deg) translate3d(-1.2em, -110%, 0);
    -webkit-transition: transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1);
    transition: transform 0.5s cubic-bezier(0.59, 0.03, 0.2, 1);
    background-color: #0000ff;
}

body button.MuiButton-outlinedPrimary.btn-outline-primary:hover {
    color: #fff !important;
    border-color: #0000ff;
}

body button.MuiButton-outlinedPrimary.btn-outline-primary:hover:after,
body button.MuiButton-outlinedPrimary.btn-outline-primary:focus:after {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    -ms-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
}

div.content-wrapper {
    max-width: 100%;
    margin-top: 0;
    padding: 0;
}

div.content-wrapper.content-wrapper-sidebar-position-none .content {
    width: 100%;
    margin: 0;
}

.thanks-section h2 {
    font-size: 37px;
    font-weight: 700 !important;
}


body .body-container .customer-panel .not-btn-style .MuiButtonBase-root {
    border: none;
    padding: 10px 0 !important;
    min-width: 30px !important;
}

body .body-container .customer-panel .not-btn-style .MuiButtonBase-root:after,
body .body-container .customer-panel .not-btn-style .MuiButtonBase-root:before,
body .body-container .customer-panel .not-btn-style .MuiOutlinedInput-root:after,
body .body-container .customer-panel .not-btn-style .MuiOutlinedInput-root::before {
    display: none !important;
}

body .body-container .customer-panel .not-btn-style .MuiButtonBase-root:hover,
body .body-container .customer-panel .not-btn-style .MuiButtonBase-root:focus {
    color: #0000ff !important;
}

body .body-container .customer-panel .not-btn-style .MuiOutlinedInput-root {
    /* padding-top: 0;
    padding-bottom: 0; */
}

body .body-container .customer-panel .not-btn-style .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    top: calc(50% - 20px);
}

body .body-container .customer-panel .not-btn-style .MuiButtonBase-root svg {
    font-size: 1.25rem !important;
}

.new-call-details {
    background-color: #fff;
}

body .shop-search button.MuiButton-containedPrimary.btn-primary {
    margin: auto;
}

body .customer-panel ul li::before {
    display: none !important;
}

body .customer-panel .MuiButtonBase-root.btn-secondary {
    border-radius: 0;
    box-shadow: none;
    z-index: 1;
}

body .customer-panel .MuiButtonBase-root.btn-secondaryL:after {
    background-color: #0000ff;
}

.sign-agreement-modal {}

.previous-value .selection-group {
    /* margin-bottom: 15px; */
}

.previous-value .selection-group h4 {
    margin-top: 0;
    margin-bottom: 10px;
}

body .customer-panel .previous-value .MuiButton-sizeSmall {
    padding: 8px 10px !important;
    /* height: auto; */
}


.customer-panel .previous-value table tr.MuiTableRow-root:nth-of-type(2n+1) {
    background-color: unset;
}


.faq-wrapper .MuiListItem-root,
.faq-wrapper .MuiButtonBase-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.faq-wrapper .MuiListItem-root {
    border-bottom: 1px solid #353535;
}

.faq-wrapper .MuiListItem-root:last-child {
    border: none;
}

body .btn-primary.modal-close {
    position: absolute !important;
    top: -15px;
    right: -15px;
}

body .customer-panel .faq-wrapper .MuiButtonBase-root {
    padding: 0 !important;
    transition: 0.3s;
    height: auto;
}

body .customer-panel .faq-wrapper .MuiButtonBase-root .MuiTypography-root {
    font-weight: 600;
    line-height: 1.2;
    font-size: 14px;
}

body .customer-panel .faq-wrapper .MuiButtonBase-root:hover,
body .customer-panel .faq-wrapper .MuiButtonBase-root:focus {
    color: #0000ff;
    background-color: unset;
}

#root .customer-panel .large-comercial input,
#root .customer-panel .large-comercial .MuiInputBase-root {
    background-color: #fff;
}

.box-header {
    text-align: center;
    font-size: 16px;
}

.box-header h6 {
    margin-bottom: 0 !important;
}

.supplier_logo_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.supplier_logo_wrapper img {
    align-self: center;
}

.supplier_logo {
    width: 80px;
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
    margin: auto !important;
}

.mobile-header .logo img {
    object-fit: contain;
}

body.page-id-7261 .elementor-section .elementor-container {
    padding: 0 !important;
}

.previous-value {
    display: flex;
    align-items: center;
    gap: 15px;
}

.small {
    font-size: 11px;
}

.previous-value .btn-primary {
    min-width: 105px !important;
}

.search-compare:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 100px;
    z-index: -1;
}

.shop-now:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 100px;
    z-index: -1;
}

#combo-box-demo li a::before,
.not-bullet li a::before {
    display: none !important;
}

.box-body .list-itm {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    line-height: 1;
}

.box-body .list-itm span {
    text-align: right;
}

.box-body .list-itm .btn-link {
    padding: 0 !important;
}

.large-comercial {
    background-color: #fff;
    padding: 60px 0;
}

.or-wrapper {
    height: 100%;
}

.or-devider {
    content: "";
    height: 100%;
    width: 1px;
    background-color: #b5b5b5;
}

.large-comercial h2 {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 40px !important;
}

.table-w-auto {
    width: auto !important;
}

.shrink-auto {
    flex-basis: auto !important;
}

.shrink-auto .MuiPaper-root {
    box-shadow: none;
}

.MuiModal-root+.MuiPaper-root ul li,
.MuiModal-root+.MuiPaper-root ul li a::before {
    list-style: none !important;
}

.account-added-row {
    margin-top: 10px !important;
}

.faq_modal p {
    font-size: 16px;
}

.supplier-logo-table table th.MuiTableCell-root,
.supplier-logo-table table td.MuiTableCell-root {
    min-width: 100px;
    padding: 6px !important;
}

@media (min-width: 600px) {
    .drawer-wrapper.css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper {
        width: 56px;
    }
}

@media (max-width: 1366.98px) {
    body .MuiTableCell-root {
        /* padding: 2px 6px; */
    }

}

@media (max-width: 1280.98px) {
    .search-compare:before {
        /* height: 120px; */
    }

    .shop-now:before {
        height: 120px;
    }

    .supplier_logo {
        width: 60px;
        max-width: 60px;
        max-height: 60px;
    }
}

@media (max-width: 1199.98px) {

    h1.section-title,
    .home-center .title,
    .hero-title h2,
    .thanks-section h2,
    .search-compare h2,
    .new-call-details h2 {
        font-size: 32px;
    }

    h2.choose-your-plan {
        font-size: 22px;
    }

    .MuiModal-root #modal-modal-title,
    .contract-review h2 {
        font-size: 32px;
    }

    .search-compare h1.section-title {
        font-size: 26px;
    }

}

@media (max-width: 1080.98px) {
    .shop-now::before {
        height: 120px;
    }

    .search-compare::before {
        height: 120px;
    }
}

@media (max-width: 1024.98px) {
    .hero-banner {
        height: 400px;
    }


    .shop-search,
    .shop-now,
    .search-compare,
    .thanks-section,
    .contract-review {
        padding: 40px 0;
    }

    .shop-now {
        /* padding-top: 0; */
    }

    .shop-search::after {
        top: 145px;
    }

    .shop-now::before {
        height: 100px;
    }

    .search-compare::before {
        height: 100px;
    }
}

@media (max-width: 991.98px) {
    .hero-banner {
        height: 350px;
    }

    .search-compare:before {
        height: 130px;
    }

    .shop-now::before {
        height: 100px;
    }


}

@media (max-width: 899.98px) {
    .or-wrapper {
        position: relative;
    }

    .or-devider {
        height: 1px;
        width: 100%;
        position: absolute;
        top: 13px;
        left: 0;
    }

    .or-wrapper h6 {
        z-index: 1;
        background: #fff;
        position: relative;
        display: initial;
        padding: 0 15px;
    }
}

@media (max-width: 850.98px) {}

@media (max-width: 767.98px) {

    .shop-search::after {
        top: 120px;
    }

    .large-comercial h2 {
        font-size: 28px;
        margin-bottom: 20px !important;
    }

    .hero-title {
        top: 50%;
        left: 50%;
    }

    .scroll-top {
        bottom: 10px;
        right: 10px;
    }

    .steps-radio label.MuiFormControlLabel-root {
        width: calc(50% - 24px);
    }

    .MuiModal-root #modal-modal-title,
    .contract-review h2 {
        font-size: 28px;
    }


    h1.section-title,
    .hero-title h2,
    h1.section-title,
    .home-center .title,
    .hero-title h2,
    .thanks-section h2,
    .search-compare h2,
    .contract-review h2,
    .new-call-details h2 {
        font-size: 28px;
        margin-bottom: 20px !important;
    }

    .search-compare h1.section-title {
        font-size: 20px;
    }

    h2.choose-your-plan {
        font-size: 20px;
    }

    .hero-banner {
        height: 300px;
    }

    .shop-now h3 {
        font-size: 14px;
    }

    .home-cards .home-card-single .MuiCardHeader-title,
    #root .home-cards .home-card-single h2 {
        font-size: 18px;
    }

    .previous-value {
        flex-direction: column;
    }

    .shop-now::before,
    .search-compare:before {
        height: 70px;
    }

    .previous-value {
        gap: 10px;
        justify-content: flex-start;
    }

}

@media (max-width: 640.98px) {
    .box-body .list-itm {
        flex-wrap: wrap;
    }

    .box-body .list-itm span {
        width: 100%;
        font-weight: 300;
        margin-top: 10px;
    }

    .box-body .list-itm .btn-link {
        width: auto;
        min-width: auto;
        border: none !important;
        color: #0000ff;
    }
}

@media (max-width: 575.98px) {
    .contract-review .contract-review-list .MuiListItemText-root span.MuiTypography-root {
        flex-wrap: wrap;
    }

    .contract-review .contract-review-list .MuiListItemText-root span.MuiTypography-root>span {
        width: 100%;
        flex: 100%;
        padding: 0 15px;
        text-align: left !important;
    }

    .supplier-logo-table table th.MuiTableCell-root,
    .supplier-logo-table table td.MuiTableCell-root {
        min-width: 150px;
    }

    .new-call-details h2 {
        text-align: left;
    }

    .footer-bottom .footer-bottom-links {
        text-align: left;
        margin-top: 20px;
    }

    button.need-help {
        display: none;
    }

    .footer-bottom .footer-bottom-links a.MuiLink-root {
        margin-left: 0;
        margin-right: 30px;
    }

    .app_bar_right.MuiBox-root {
        position: absolute;
        right: 10px;
    }
}

@media (max-width: 480.98px) {
    .steps-radio label.MuiFormControlLabel-root {
        width: 100%;
    }

    div.faq_modal {
        width: 70%;
        padding: 40px;
    }

}

.MuiTooltip-tooltip h3 {
    font-size: 14px;
    line-height: 1.5;
}

div#notistack-snackbar {
    font-size: 18px;
    font-weight: bold;
}

.blue-light_bulb.advance-box img {
    margin: 0;
    max-width: 28px;
    height: auto;
    object-fit: cover;
}

.blue-light_bulb.advance-box {
    display: flex;
    vertical-align: top;
    left: 0px !important;
    margin-bottom: 20px;
}

.blue-light_bulb.advance-box span.blub-text {
    margin-left: 10px;
    font-size: 14px;
}

.SnackbarItem-wrappedRoot {
    z-index: 999999 !important;
}

body .customer-panel .button.requested-date-btn {
    padding: 0 !important;
    border: none !important;
    min-width: 40px !important;
}

body .customer-panel .button.requested-date-btn:hover svg {
    color: #0000ff !important;
}

body .customer-panel .MuiButtonBase-root.large-submit .MuiLoadingButton-loadingIndicator {
    top: 10px !important;
    right: 10px !important;
}

.esid-look-up {
    background-color: #fff;
    padding: 60px 0 100px 0;
}

.esid-look-up .section-title {
    font-size: 37px;
    font-weight: 700;
}

.esid-look-up .MuiInputBase-root {
    border: none;
}

.esid-look-up .MuiInputBase-root::before {
    display: none;
}

.esid-look-up .MuiAutocomplete-endAdornment {
    display: none;
}

.look-up-search {
    position: relative;
}

body .customer-panel .look-up-search .MuiButtonBase-root {
    padding: 8px !important;
    width: 54px;
    top: 0;
    right: 0;
    position: absolute;
    border: 2px solid blue !important;
    height: 54px;
    border-radius: 0;
}

body .customer-panel .look-up-search .MuiButtonBase-root:hover {
    color: #0000ff !important;
}

.look-up-results {
    margin-top: 20px;
}

.look-up-results .look-up-results-inner {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
}

.look-up-results .look-up-results-itm {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    width: calc(50% - 15px);

}

.look-up-results .look-up-results-itm span {
    font-weight: 400;
    margin-left: 10px;
}

@media (max-width: 991px) {
    .look-up-results .look-up-results-itm {
        width: 100%;
        line-height: 1.3;
    }
}
