@import "../../colorPalette.css";
.header.header-position-above.sticky-header-on.sticky-ready.sticky-active .sticky-wrapper{
  display: none !important;
}

.display-none {
  display: none;
}

.padd-right-16 {
  padding-right: 16px;
}

.display-linebreak {
  white-space: pre-line;
}

.fnt-bold {
  font-weight: bold;
}

.ef5-modal-container {
  position: relative;
}

.button-icon {
  border: 2px solid #a7acfa;
  border-radius: 32px;
  padding: 24px 24px;
  display: flex;
  cursor: pointer;
  width: calc(100% - 60px);
  vertical-align: middle;
  margin: 0px 10px;
  &.disabled {
    border-color: #e8e8e8;
  }
  .img-icon {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #d8d8d8;
    margin-right: 24px;
    img {
      max-width: 80px;
    }
  }
  h2 {
    text-transform: uppercase;
    margin: 0px !important;
    font-weight: bolder;
    font-size: 15px !important;
    text-align: left;
    font-family: Inter;
    line-height: 18px !important;
    height: 80px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.alert-message {
  border: 2px solid #e58b90;
  border-radius: 20px;
  padding: 18px;
  display: flex;
  cursor: pointer;
  background-color: #f7e0e1;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
  .img-icon {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 2px;
    margin-right: 24px;
  }
  h2 {
    margin: 0px !important;
    padding-top: 10px;
    font-weight: bolder;
    font-size: 14px !important;
    text-align: left;
    font-family: Inter;
    line-height: 24px !important;
    padding-left: 0px;
    text-align: center;
    vertical-align: middle;
  }
}
.label-date {
  border: 2px solid #a7acfa;
  border-radius: 32px;
  padding: 24px 24px;
  position: relative;
  width: 400px;
  line-height: 3;
  font-weight: bolder;
  > .MuiFormControl-root {
    width: 300px;
    position: absolute;
    bottom: -20px;
    left: 70px;
    background-color: #d8d8d8;
    border-radius: 30px !important;
    fieldset {
      border-radius: 30px !important;
      border: none !important;
    }
  }
}
.tooltipIcon {
  font-size: 10px;
  position: relative;
  bottom: 10px;
}
.tooltipIcon-small {
  font-size: 10px;
  position: relative;
  bottom: 5px;
}
.tooltip {
  height: auto !important;
  line-height: 0 !important;
  margin-left: 8px !important;
  padding: 0 !important;
  border: none !important;
}

.radio-buttons {
  display: flex;
  width: 100%;
  align-content: space-around;
}
.title-bar {
  font-weight: normal !important;
  font-size: 30px;
}
.steps-bar {
  background-color: #d2d2d2;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  .circle {
    background-color: #0021f5;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    border: 2px solid #d2d2d2;
    text-align: center;
    /*margin-left: 4px;
    margin-right: 4px;*/
    &.start {
      font-size: 12px;
      line-height: 21px;
    }
    &.end {
      font-size: 15px;
      line-height: 22px;
    }
    &.selected {
      background-color: white;
      border-color: #0021f5;
      color: #0021f5;
    }
    &.next-step {
      background-color: white;
      color: #0021f5;
    }
    &.small {
      width: 20px;
      height: 20px;
    }
  }
  .line {
    width: 15px;
    height: 0px;
    border: 1px solid #0021f5;
    &.small {
      width: 7px;
    }
  }
}

.shop-now div.offer-container {
  padding-top: 24px;
  padding-bottom: 24px;
}

.shop-now button:after {
  content: none !important;
}
.shop-now h2 {
  font-family: "SF Pro Text Regular", "Manrope", sans-serif !important;
  font-weight: 400;
  line-height: 24px !important;
  font-size: 24px;
}
.shop-now .sectionTitleContainer > h2 {
  margin: 37px !important;
}
.shop-now .MuiGrid-root > button {
  font-family: "Inter" !important;
  font-weight: 700;
  font-size: 16px;
  color: white !important;
  line-height: 18px !important;
  padding: 24px 16px !important;
  padding-top: 15px !important;
  border-radius: 30px !important;
  background-color: #353535;
  text-transform: uppercase;
  width: 200px !important;
  height: 50px;
  display: inline-block;
  border: none !important;
  &.btn-normal {
    display: flex;
  }
  &.next,
  &.apply-filter {
    background-color: #0021f5;
  }
  &.urgent {
    padding-top: 7px !important;
  }
}

.radio-label {
  padding: 0px 10px 0px 0px;
  padding-left: 30px;
  text-wrap: pretty;
  color: #535353 !important;
}
.splitter {
  color: #c7c7c7 !important;
  vertical-align: center;
  font-size: 29px !important;
}

.MuiFormControl-root.calendar > div.MuiOutlinedInput-root {
  flex-direction: row-reverse;
}
.MuiFormControl-root.calendar > div.MuiOutlinedInput-root button {
  margin: 20px;
  padding-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #d8d8d8 !important;
}
.MuiFormControl-root.calendar > div.MuiOutlinedInput-root button :hover {
  color: blue !important;
}

.shop-now .MuiGrid-root > button:not(.next):hover {
  color: white !important;
  background-color: #353535 !important;
}
.shop-now .MuiCardActions-root > button,
.shop-now .banner-actions > button {
  border: none;
  color: #1976d2;
  display: inline-flex;
}
.shop-now .banner-actions > button > img {
  margin-top: 3px;
}
.shop-now .banner-actions > button:first-child:hover {
  color: #1976d2 !important;
}
.shop-now .banner-actions > button:last-child {
  background-color: #2d3648;
}
.shop-now .MuiCardActions-root > button.next {
  background-color: #0021f5;
  font-weight: bolder;
}
ul.offer-perks-list > li:before {
  display: none;
}
ul.offer-perks-list > li {
  display: ruby-text;
}
thead th.txt-align-left {
  color: var(--blue);
  font-weight: bold;
}
tr td.display-linebreak {
  font-style: italic;
}
.or-label {
  margin-top: 50px;
  position: absolute;
  top: 10px;
  left: -26px;
  font-size: 13px;
}

.shop-now span.MuiTypography-root {
  font-family: "SF Pro Text Regular", "Manrope", sans-serif !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px !important;
}
.MuiInputLabel-sizeSmall.Mui-error {
  margin-left: calc(50% - 56px);
}

.bulbImage {
  height: 301px;
  width: 280px;
  align-self: center !important;
  vertical-align: middle !important;
}
.zipFieldPhoneLabel {
  font-size: 22px;
  color: #0000ff !important;
}

.referralFieldsContainer {
  width: auto !important;
}
.enterZipField {
  left: 0;
  width: 25%;
}
.enterBusinessField {
  left: 10%;
  width: 75%;
}

.sectionTitleContainer {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--black);
  position: relative;
}
.displayF {
  display: flex;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.tcBlue {
  color: #0000ff;
}

.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}

.signcontract-button {
  vertical-align: center !important;
}
.editcontract-button :hover {
  color: #0000ff !important;
}

button.btn-eb5 {
  width: auto;
  height: 41px;
  padding: 24px 16px;
  border-radius: 30px !important;
  line-height: 18px;
  color: var(--primary-gray);
  text-transform: uppercase;
  width: 200px;
  font-family: "Inter" !important;
  font-weight: 700;
  font-size: 16px;

  &.width-full {
    width: 90%;
    &.disable {
      opacity: 0.4;
    }
  }
  &.bordered {
    border: 2px solid var(--primary-gray);
    &:hover {
      border: 2px solid var(--primary-gray);
    }
  }
  &.white-txt {
    color: var(--white);
  }
}

span.sub-text {
  font-weight: bold;
  font-size: 24px;
}

.pt50 {
  padding-top: 50px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px;
}

.inputLabel {
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  margin-bottom: 10px;
  &.fix-tooltip {
    padding: 0 30px;
    text-align: center;
    margin-bottom: 10px;
    button {
      position: relative;
      svg {
        position: absolute;
        left: -6px;
      }
    }
  }
}
button.MuiButtonBase-root.MuiIconButton-sizeMedium.tooltip {
  height: 42px !important;
  width: 20px !important;
  min-width: 25px !important;
  margin-top: -25px;
}
button.MuiButtonBase-root.MuiIconButton-sizeMedium.tooltip svg {
  left: 0px;
  top: 22px;
  display: block;
  width: 16px;
}
button.MuiButtonBase-root.MuiIconButton-sizeMedium.tooltip svg:hover {
  color: black;
}
.tooltip {
  height: auto !important;
  line-height: 0 !important;
  margin-left: 8px !important;
  padding: 0 !important;
  border: none !important;
}

.completeLabel {
  font-size: 16px;
}

.question h3 {
  font-family: "SF Pro Text Regular", "Manrope", sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: 0em;
  text-align: left;
}

.fnt-size24 {
  font-size: 24px;
}

.thanks-info {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.09px;
}

.thanks-info ul,
.thanks-content .phone {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding: 20px 0;
}

.thanks-info ul button {
  margin-left: 35px;
}

.thanks-content p {
  font-size: 16px;
  line-height: 24px;
}

.thanks-content .phone {
  margin: 0;
}

.fs-italic {
  font-style: italic;
  font-weight: 700;
}

#root div.card-eb5 {
  border-radius: 20px;
  min-width: 335px;
  div.card-title {
    padding: 5px 5px 5px 15px;
    text-align: left;
    color: var(--white);

    span {
      font-size: 14px;
      font-weight: bolder;
    }

    &.option-1 {
      background: var(--green);
    }

    &.option-2 {
      background: var(--blue);
    }

    &.option-3 {
      background: var(--violet);
    }
  }

  & > div {
    background: var(--clear-gray);
  }

  div.offer-main-data {
    display: flex;
    text-align: right;
    font-weight: bold;
    font-size: 36px;

    .supplier-logo {
      padding-left: 20px;
      min-width: 60px;
      max-width: 60px;
      max-height: 60px;
      align-self: auto !important;
    }

    .offer-card-price {
      margin-left: auto;

      span:first-child {
        font-size: 40px;
      }

      span:last-child {
        font-size: 16px;
      }
    }
  }

  div.offer-extra-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding-top: 10px;

    span {
      font-size: 16px;
    }
  }

  div.card-actions-container {
    & button:last-child {
      margin-left: auto;
    }
  }
}

.filters-container {
  padding-top: 40px;
  .select-box {
    padding-top: 5px;
  }
  .MuiSelect-select,
  .date-picker-input input {
    padding: 16.5px 14px;
  }
  .filter-actions-button {
    margin-bottom: 30px;
  }
}

.tooltipIcon {
  font-size: 14px;
}

div.offers-table-section {
  padding-top: 30px;

  div.offers-table-container {
    padding-top: 30px;

    .supplier-logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;

      img.supplier-logo {
        align-self: center;
        width: 80px;
        max-width: 80px;
        max-height: 80px;
        object-fit: contain;
        margin: auto;
      }
    }

    tr td:first-child {
      cursor: default;
    }

    td {
      cursor: pointer;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .best-offer-table-cell-1 {
      background: var(--green);
      color: var(--white);
    }

    .best-offer-table-cell-2 {
      background: var(--blue);
      color: var(--white);
    }

    .best-offer-table-cell-3 {
      background: var(--violet);
      color: var(--white);
    }
  }
}

div.compare-banner .banner-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  background: var(--clear-gray);

  .banner-header {
    justify-content: flex-start;
  }

  .banner-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    padding-left: 20px;

    button {
      font-size: 20px;
      img {
        margin-right: 7px;
      }
    }

    & button:nth-child(1n) {
      margin-bottom: 10px;
    }
  }
}

div.small-card {
  background: var(--white);
  border-radius: 25px;
  width: 100%;
  min-height: 150px;

  .small-card-container {
    .close-btn {
      position: absolute;
      right: 0;
      margin: 10px 20px 0 0;
      font-size: 50px;

      &:hover {
        cursor: pointer;
      }
    }

    .small-card-body {
      display: flex;
      padding-top: 35px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .supplier-logo {
      width: 60px;
      max-width: 60px;
      max-height: 32px;
      margin: 0 auto;
    }

    .small-card-price {
      width: auto;
      margin-top: 15px;
      font-weight: bold;

      span:first-child {
        font-size: 40px;
      }

      span:last-child {
        font-size: 16px;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 215px;
  left: 0px;
  width: 100%;
  height: calc(100% - 215px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--white);
  z-index: 9;
  h2 {
    margin-bottom: 80px;
    line-height: 32px;
    font-size: 24px;
  }
  img.image-loading {
    align-self: center !important;
    vertical-align: middle !important;
  }
}

body .MuiModal-root .offer-compare-modal-container,
body .MuiModal-root .utilities-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border: 2px solid var(--black);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  border-radius: 10px;
}

body .MuiModal-root .offer-compare-modal-container {
  .offer-compare-modal-mask {
    position: relative;
  }
  &.budget-section {
    width: 80vw;
    background: transparent;
    border: none;
    box-shadow: none;
    .close-control-actions {
      background-color : var(--white);
      a {
        color: var(--black);
      }
    }
  }
  .close-control-actions {
    position: absolute;
    right: 0;
    background-color: var(--black);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    a {
      color: var(--white);
    }
    & a:visited {
      color: inherit;
    }
  }
  .offer-container {
    max-width: 100% !important;
    .offer-card-container {
      .card-body {
        max-height: 385px;
      }
      .description-fields-section {
        flex-direction: column;
        align-items: end;
        font-size: 16px;
        gap: 5px;
        padding-top: 7px;
        text-align: end;
      }
      .offer-rate.big-row {
        height: 65px;
        align-items: center;
        display: flex;
      }
    }

    .card-eb5 {
      border-radius: 30px;
      background: var(--clear-gray);

      .card-title {
        padding: 5px 5px 5px 15px;
        text-align: center;
        color: var(--white);

        span {
          font-size: 14px;
        }

        &.option-1 {
          background: var(--green);
        }

        &.option-2 {
          background: var(--blue);
        }

        &.option-3 {
          background: var(--violet);
        }
      }

      .offer-price {
        font-size: 24px;
        padding: 10px 0;
        font-weight: bold;
      }

      .offer-main-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        gap: 5px;

        .supplier-logo {
          width: 60px;
          height: 32px;
          max-width: 60px;
        }
      }

      .card-actions-container {
        justify-content: center;
        button {
          padding: 10px;
        }
      }
    }
  }
}

.compare-button {
  background-color: #f5f5f4 !important;
}

li.MuiMenuItem-gutters:before {
  content: none !important;
}

.MuiTooltip-popper div{
  line-height: 20px;
  font-size: 18px;
  padding:10px;
}
.compare-modal-close{
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 42px;
  cursor: pointer;
}
.MuiButton-text.MuiButton-textPrimary:hover{
  color: #1976d2 !important;
}
.content-wrapper .content{
  z-index:4
}
body .MuiModal-root .utilities-modal-container {
  width: 480px;
  .utility-select-box {
    padding: 30px 0;
  }

  .utility-message {
    display: flex;
    flex-direction: column;
  }

  .utilities-modal-actions {
    display: flex;
    button {
      margin-left: auto;
    }
  }

  .no-utilities-message {
    flex-direction: column;
    .message-title {
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 30px;
    }
  }

  button.btn-eb5.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    width: 220px;
    height: auto;
  }
}

.form-container {
  .field-container {
    margin: 5px 0;
  }
  .flex {
    display: flex;
    & > * {
      flex: 1;
    }
    .field-container:nth-child(odd) {
      padding-right: 5px;
    }
    .field-container:nth-child(even) {
      padding: 0 5px;
    }
    .field-container:last-child {
      padding: 0;
      padding-left: 5px;
    }
  }
  .delete-section {
    text-align: right;
    align-items: center;
    justify-content: right;
    display: flex;
  }
  button.btn-normal {
    width: auto !important;
    background: transparent;
    color: black !important;
    font-size: 13px;
    font-weight: 500;
    padding: 0 !important;
    margin-top: 20px;
    svg {
      font-size: 45px;
      color: #0021f5;
    }
  }
  button.btn-delete {
    width: auto !important;
    background: transparent !important;
    color: black !important;
    font-size: 11px;
    font-weight: 400;
    padding: 0 !important;
    svg {
      color: red;
    }
  }
}
.contract-review {
  & .information-container {
    padding: 0 !important;
    overflow: hidden;
    & .title-container {
      padding: 26px 0 !important;
      background-color: #0021f5;
      color: white !important;
      display: flex;
      justify-content: center;
      & .section-title {
        margin: 0 !important;
        color: white !important;
      }
      & h2 {
        color:white !important;
      }
    }
    & .info-container {
      padding: 26px 0 !important;
    }

    &.summary-box {
      padding: 0 !important;
    }
  }
}

.information-container {
  .MuiGrid-grid-xs-6:nth-child(odd) {
    padding-right: 20px;
  }
  .MuiGrid-grid-xs-6:nth-child(even) {
    padding-left: 20px;
  }
  &.summary-box {
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 32px;
    box-shadow: 0px 3px 6px -4px #0000001f;
    box-shadow: 0px 6px 16px 0px #00000014;
    box-shadow: 0px 9px 28px 8px #0000000d;
  }
  &.bg-gray {
    background-color: #eeeeee;
  }
  .info-header {
    display: flex;
    align-items: center;
    .supplier-logo {
      max-height: 30px;
      max-width: 90px;
    }
    img {
      margin-right: 20px;
    }
  }
  .custom-align {
    display: flex;
    span {
      width: 50%;
      text-align: right;
      &.left {
        text-align: left;
        font-weight: 700;
      }
    }
  }
}
.grid-information .MuiAlert-root {
  margin: 20px 0;
}

.offer-summary .form-container{
  & .authorization-check label span.MuiTypography-root {
    font-size: 15px !important;
  }
  & .billing-address label span.MuiTypography-root {
    font-size: 15px !important;
  }

}
.offer-summary .information-container{
  & .info-header {
    & h2 {
      margin: 0 !important;
    }
  }
  & p {
    font-size: 15px !important;
    font-weight: 700;
  }
  & h4{
    font-size: 19px !important;
    margin-top: 20px !important;
    font-weight: 600;
  }
  & h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0;
  }
  .authorization-check {
    padding-top: 20px;
    .MuiFormControlLabel-label {
      font-size: 15px !important;
    }
  }
  .billing-address {
    padding: 30px 0px 30px 0px !important;
  }
}
.inputLabel.big.oneline {
  text-align: center;
  padding: 0 20px;
  &.short {
    padding: 0 40px;
  }
}

.section-gap-20 {
  height: 20px;
}
.section-gap-30 {
  height: 30px;
}

.radioGrid {
  padding-top: 20px;
}

.offer-compare-modal-container.details-description-container {
  display: block;

  .offer-container {
    overflow-y: auto;
    max-height: 70vh;
  }

  .offer-details-actions {
    position: absolute;
    bottom: 10px;
    right: 5vh;

    button:first-child {
      margin-right: 20px;
    }
  }
}

.offer-card-container.big-card-eb5 {
  margin-top: 20px;
  border-radius: 30px;
  background: var(--clear-gray);
  border: 1px solid rgba(0, 0, 0, 0.12);

  .card-title-container {
    padding: 0;

    .card-title {
      padding: 5px 5px 5px 15px;
      text-align: center;
      color: var(--white);
      display: flex;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      background-color: black;
    }
  }

  .offer-details-logo {
    img {
      height: 50px;
    }
  }

  .offer-details-actions {
    display: flex;
    padding: 20px;

    button:first-child {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .offer-details-logo {
    margin: auto 0px;
    margin-left: 10px;
  }

  .offer-price {
    justify-content: end;

    p {
      margin-right: 40px;

      .price-value {
        font-size: 36px;
        font-weight: bold;
      }
    }
  }

  .utility-description {
    width: 80%;
  }

  .offer-perks {
    margin-left: 30px;

    .offer-perks-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 40px;

      li img {
        padding-right: 5px;
        height: 11px;
        width: 11px;
      }
    }
  }

  .offer-data-explanation {
    margin: 0 30px;
  }

  .offer-data-explanation .offer-contracts-explanation {
    .contract-info {
      margin: 20px 0;

      .contract-info-title {
        font-weight: bold;
        color: var(--blue);
      }
    }
  }

  .tables-summary {
    display: flex;
    width: 97%;
    background: var(--white);
    align-items: center;
    border-radius: 30px;
    margin-bottom: 30px;

    .price-details-table {
      width: inherit;
      border-collapse: collapse;
      margin: 20px;

      thead th {
        padding-bottom: 5px;
        border-bottom: 3px solid var(--black);
      }

      tbody {
        tr:first-child {
          th,
          td {
            padding: 30px 0 10px 0;
          }
          td {
            max-width: 255px;
          }
        }
        tr:last-child {
          th,
          td {
            padding: 10px 0 30px 0;
          }
          td {
            min-width: 150px;
            max-width: 255px;
          }
        }
      }

      tfoot {
        th,
        td {
          padding-top: 20px;
          border-top: 3px solid var(--black);
          font-weight: bold;
        }
      }

      .txt-align-right {
        text-align: right;
      }

      .txt-align-left {
        text-align: left;
        width: 169px;
      }

      .flex-align-right {
        display: flex;
        justify-content: end;
        margin-left: auto;
      }
    }
  }

  .tables-summary.table-long-name {
    th[scope="row"] {
      font-weight: unset;
      min-width: 300px;
    }
    tfoot th.font-bold {
      font-weight: bold;
    }
  }
}

.table-alert {
  border-radius: 30px;
  padding: 10px 0;
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}

.contract-review {
  .btn-eb5 {
    background-color: #353535;
    color: white;
    &:hover {
      color: #353535;
    }
  }
}
.custom-autocomplete
  .MuiInputBase-root
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root {
  border-color: transparent !important;
  &:hover {
    color: black;
    background-color: transparent;
  }
}
button .MuiLoadingButton-loadingIndicator {
  color: #ffffff;
}
button.MuiButtonBase-root.disabled {
  color: #b4aeae;
}
@media only screen and (max-width: 600px) {
  .shop-now .MuiGrid-root > button.big {
    height: 100px;
    font-size: 16px;
  }
  .inputLabel.big.oneline {
    line-height: 41px;
  }
  .inputLabel.big {
    height: 41px;
    font-size: 12px;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
